function tabsOneBtn({tabsParentSelector, articleCards, btnSelector}) {

    const tabsParent = document.querySelectorAll(tabsParentSelector);

    tabsParent.forEach(item => {
        const cards = item.querySelectorAll(articleCards),
              toggleBtn = item.querySelector(btnSelector);

        cards.forEach(card => {
            card.classList.add('hide');
        })
   

        function showCards(a, c) {
            for(let i = a; i < c; i++) {
            cards[i].classList.remove('hide');
            }
        }

        showCards(0, 3);

        let counter = 3;
        
        toggleBtn.addEventListener('click', () => {
            cards.forEach(card => {
                card.classList.add('hide');
            })

            if(counter == cards.length) {
                showCards(0, 3);
                counter = 3;
            } else {
                showCards(counter, counter+3);
                counter += 3;
            }
               
            item.scrollIntoView({block:"start", behavior: "smooth"});
        })

    })

}

export default tabsOneBtn;