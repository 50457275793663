function readMoreText({commentSelector, textBox, textSelector,openBtn, closingBtn, openClass, cutLenghtDesc, cutLenghtMobile}) {
    const comment = document.querySelectorAll(commentSelector);

    comment.forEach(item => {
        const textContainer = item.querySelector(textBox),
              text = item.querySelector(textSelector),
              readMoreBtn = item.querySelector(openBtn),
              closeBtn = item.querySelector(closingBtn);

        let fullText = text.innerHTML;    

        if(window.innerWidth > 1200) {
            if(text.innerHTML.length > cutLenghtDesc) {
                text.innerHTML = `${text.innerHTML.substring(0, cutLenghtDesc)}...`;
            } else {
                readMoreBtn.classList.add('hide');
            }
        } else {
            if(text.innerHTML.length > cutLenghtMobile) {
                text.innerHTML = `${text.innerHTML.substring(0, cutLenghtMobile)}...`;
            } else {
                readMoreBtn.classList.add('hide');
            }
        }

        readMoreBtn.addEventListener('click', ()=> {
            text.innerHTML = fullText;
            readMoreBtn.classList.add('hide');
            closeBtn.classList.remove('hide');

            if(window.innerWidth > 1200) {
                textContainer.classList.add(openClass);
            } 
        });

        closeBtn.addEventListener('click', ()=> {
            if(window.innerWidth > 1200) {
                if(text.innerHTML.length > cutLenghtDesc) {
                    text.innerHTML = `${text.innerHTML.substring(0, cutLenghtDesc)}...`;
                } else {
                    readMoreBtn.classList.add('hide');
                }
            } else {
                if(text.innerHTML.length > cutLenghtMobile) {
                    text.innerHTML = `${text.innerHTML.substring(0, cutLenghtMobile)}...`;
                } else {
                    readMoreBtn.classList.add('hide');
                }
            }
       
            closeBtn.classList.add('hide');
            readMoreBtn.classList.remove('hide');

            if(window.innerWidth > 1200) {
                textContainer.classList.remove(openClass);
            }

            if(window.innerWidth < 1200) {
                textContainer.scrollIntoView({block:"start", behavior: "smooth"});
            }
        });

    });
}

export default readMoreText;