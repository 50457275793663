
function openMenu()  {
  const parent = document.querySelector('.header'),
        targetBurger = parent.querySelector('.target-burger'),
        navWrapper = parent.querySelector('.wrapper_nav'),
        nav = parent.querySelector('.nav'),
        main = document.querySelector('main.main'),
        footer = document.querySelector('footer'),
        body = document.querySelector('body');

      targetBurger.addEventListener('click', (e) => {
        e.preventDefault();
  
          targetBurger.classList.toggle('toggled');
          nav.classList.toggle('toggled_nav');
          navWrapper.classList.toggle('show');
          main.classList.toggle('blur');
          footer.classList.toggle('blur');
          body.classList.toggle('stop-scrolling');
        
    })

 
}

export default openMenu;