function tabsPortfolioPreview() {
    const tabsParent = document.querySelectorAll(".portfolio_preview__slide");

    tabsParent.forEach(item => {
        let tabsBtnBox = item.querySelector('.smaller_img__container'),
            tabsBtnSlider = item.querySelector('.smaller_img__slider'),
            tabsBtn = item.querySelectorAll('[data-tabsBtn]'),
            tabsBtnWidth = window.getComputedStyle(tabsBtn[0]).width,
            widthForSmaller = window.getComputedStyle(tabsBtnBox).width,
            tabsContentsBox = item.querySelector('.big_img__container'),
            tabsContentSlider = item.querySelector('.biggest_img__slider'),
            tabsContent = item.querySelectorAll('[data-tabContent]'),
            widthForBiggest = window.getComputedStyle(tabsContentsBox).width;

        let offsetBtn = 0;
        let offsetContent = 0;

        tabsBtnSlider.style.width = 100 * tabsBtn.length + '%';
        tabsContentSlider.style.width = 100 * tabsContent.length + '%';

        tabsBtn.forEach(slide => {
            slide.style.width = widthForSmaller;
        });

        tabsContent.forEach(item => {
            item.style.width = widthForBiggest;
        });

        function deleteNotDegets(str) {
            return +str.replace(/\D/g, '');
        }

        function moveBtn() {
            if(offsetBtn == deleteNotDegets(tabsBtnWidth) * (tabsContent.length - 1)){
                offsetBtn = 0;
            } else {
                offsetBtn += deleteNotDegets(tabsBtnWidth);
            }
    
            tabsBtnSlider.style.transform = `translateX(-${offsetBtn}px)`;
    
        }

        function moveContent() {
            if(offsetContent == deleteNotDegets(widthForBiggest) * (tabsContent.length - 1)){
                offsetContent = 0;
            } else {
                offsetContent += deleteNotDegets(widthForBiggest);
            }
    
            tabsContentSlider.style.transform = `translateX(-${offsetContent}px)`;
        }


        tabsBtnBox.addEventListener('click', () => {
            moveBtn();
            moveContent();
        })
    })

}

export default tabsPortfolioPreview;