

function openSubmenu(parentSelector, mainMenuBtn, subMenuElement) {
    const parent = document.querySelectorAll(parentSelector);

    parent.forEach(item => {
        const mainMenuItem = item.querySelector(mainMenuBtn),
              
             submenu = item.querySelector(subMenuElement);

        mainMenuItem.addEventListener('click', (e) => {
            e.preventDefault();
            submenu.classList.toggle('show');
            item.classList.toggle('showed');

            if(item.classList.contains('designer_team__card')) {
                item.querySelector('.designer_text__container').classList.toggle("scroll");

                mainMenuItem.classList.toggle('show');
            }
            
        })

        document.addEventListener('click', (e) => {
            if(!e.target.classList.contains('nav__item__link')) {
                submenu.classList.remove('show');
            }
        })
    });  
}


export default openSubmenu;