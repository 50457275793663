import {closeModal, openModal} from './modal';

function openThanksModal(modalSelector, changedModal) {

    const formSent = document.querySelectorAll('form');

    formSent.forEach(item => {

      item.addEventListener('wpcf7mailsent', (e) => {
        e.preventDefault();
  
          if (e.target.wpcf7.id == 7){
              openModal(modalSelector);
              closeModal(changedModal);
              setTimeout(() => closeModal(modalSelector), 3000); 
            } else {
                openModal(modalSelector);
                setTimeout(() => closeModal(modalSelector), 3000); 
            } 
      })  
    });
}

export default openThanksModal;
