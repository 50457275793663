function showPreviewImages() {
    const smallerImgContainer = document.querySelector('[data-smallerContainer]'),
          textContainer = document.querySelector('[data-textContainer]'),
          smallerImgArr = document.querySelectorAll('[data-tabBtnDesc]'),
          biggerImgArr = document.querySelectorAll('[data-tabContentDecs]'),
          currentCount = document.querySelector('.portfolio_current'),
          toLeftArr = document.querySelector(".to_left"),
          toRightArr = document.querySelector(".to_right"),
          textContentArr = document.querySelectorAll('[data-text]');

          let smallImg = {
              one: smallerImgArr[0].innerHTML,
              two: smallerImgArr[1].innerHTML,
              tree: smallerImgArr[2].innerHTML
          };

        let textContent = [textContentArr[0].innerHTML, textContentArr[1].innerHTML, textContentArr[2].innerHTML]

        let current = 1;
        let click = 0;
 
        textContainer.innerHTML =  textContent[0];
    
        smallerImgContainer.innerHTML = `<div class="portfolio_img__container__sm" data-tabsBtn data-tabButton>${smallImg.one}</div>
        <div class="portfolio_img__container__sm" data-tabsBtn data-tabButton>${smallImg.two}</div>
        <div class="portfolio_img__container__sm" data-tabsBtn data-tabButton>${smallImg.tree}</div>`;

        const tabBtns = document.querySelectorAll('[data-tabButton]');

        function hideTabContent() { 
            biggerImgArr.forEach(img => {
                img.classList.add('hide');
            });
        }

        function showTabContent(i = 0) {
            biggerImgArr[i].classList.remove('hide');
            textContainer.innerHTML =  textContent[i];
            currentCount.innerHTML = `0${i+1}`
        }

        hideTabContent();
        showTabContent();

            tabBtns.forEach((item, i) => {
                item.addEventListener('click', ()=> {
                    hideTabContent();
                    showTabContent(i);
                    
                })  
            });

    
            toRightArr.addEventListener('click', ()=> {
                hideTabContent();

                if(click == biggerImgArr.length - 1){
                    click = 0;
                    toLeftArr.style.opacity = "0.45";
                } else {
                    click += 1;
                    toLeftArr.style.opacity = "1";
                }

                showTabContent(click);
            })  

            toLeftArr.addEventListener('click', ()=> {
                hideTabContent();

                if(click == 0){
                    click = biggerImgArr.length - 1;
                    toLeftArr.style.opacity = "0.45";
                } else {
                    click -= 1;
                    toLeftArr.style.opacity = "1";
                }

                showTabContent(click);
            })  
    
    
}

export default showPreviewImages;