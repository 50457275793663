import 'slick-carousel';
import $ from "jquery";


function sliderWithoutnumbers({slider, prevArrow, nextArrow, arrowsPar, dotsPar, speedPar, firstSlidePar, variableWidthPar,centerModePar, slidesToShowPar, slidesToScrollPar}) {
    const sliderContainer = $(slider);

    sliderContainer.slick({
        arrows: arrowsPar,
        nextArrow: nextArrow,
        prevArrow: prevArrow,
        dots: dotsPar,
        speed: speedPar,
        initialSlide: firstSlidePar,
        variableWidth: variableWidthPar,
        centerMode: centerModePar,
        slidesToShow: slidesToShowPar,
        slidesToScroll: slidesToScrollPar
      });
}


export default sliderWithoutnumbers;