'use strict';

// lazyloading for images
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import jQuery from 'jquery';
// import 'slick-carousel';
// import $ from "jquery";
import Inputmask from "inputmask";
import openMenu from './modules/openMenu';
import showYear from './modules/year';
import openSubmenu from './modules/openSubmenu';
import modal from './modules/modal';
import openThanksModal from './modules/openThanksModal';
import sliderWithoutnumbers from './modules/sliderWithoutnumbers';
import sliderWithSetings from './modules/sliderWithSetings';
import swipeToRight from './modules/swipeToRight';
import openItemsMobile from './modules/openItemsMobile';
import readMoreText from './modules/readMoreText';
import checkRating from './modules/checkRating';
import checkCheckbox from './modules/checkCheckbox';
import calc from './modules/calc';
import tabsPortfolioPreview from './modules/tabsPortfolioPreview';
import showPreviewImages from './modules/showPreviewImages';
import tabsOneBtn from './modules/tabsOneBtn';
import showTextOnBlur from './modules/showTextOnBlur';


window.addEventListener('DOMContentLoaded', () => {

  function replaceText({parentSelector, textSelector, textBlock}) {
    const parent = document.querySelectorAll(parentSelector);

    parent.forEach(item => {
      const replasedText = item.querySelector(textSelector),
          text = textBlock;

          replasedText.innerHTML = text;
    })
  }

    // показать актуальный год
    try {
        showYear();
      } catch (e) {
        // console.log(e);
      }
    
    // открыть или закрыть меню

    try {
      if (window.innerWidth < 1200) {
        openMenu();
      }
       
      } catch (e) {
        // console.log(e);
      }

      console.dir(document.querySelector("input"));

      try {
        function showBgOnHeader() {
          const header = document.querySelector('header'),
                headerSubmenu = header.querySelector('.submenu');

          window.addEventListener('scroll', (e) => {
          
            if(scrollY == 0) {
              header.classList.remove('header_blur');
              headerSubmenu.classList.remove('submenu_on_scroll');
            } else {
              header.classList.add('header_blur');
              headerSubmenu.classList.add('submenu_on_scroll');
            }
          })
        }

        showBgOnHeader();
      } catch (e) {
        // console.log(e);
      }
    

      //open header submenu
      try {
        openSubmenu('[data-sub_parent]', '[data-nav_with_sub]', '[data-nav_sub]');
      } catch (e) {
        // console.log(e);
      }

      // home first slider
      try {
          sliderWithSetings({
            slide: '.slider_item',
            slider: "[data-slider]",
            prevArrow: ".arrow--to_right",
            nextArrow: ".arrow--to_left",
            currentCounter: '.counter',
            arrowsPar: false,
            dotsPar: true,
            appendDotsSelector: '.move_line',
            speedPar: 700,
            firstSlidePar: 0,
            variableWidthPar: false,
            centerModePar: false,
            slidesToShowPar: 1,
            slidesToScrollPar: 1
          });   
      } catch (e) {
        // console.log(e);
      }

    // portfolio slider

    try {
      if (window.innerWidth < 1200) {
        tabsPortfolioPreview();
      } 
      
    } catch (e) {
      // console.log(e);
    }

    try {
      if (window.innerWidth > 1200) {
        showPreviewImages();
      } 
    } catch (e) {
      // console.log(e);
    }

    // portfolio 
    

    try {
      if (window.innerWidth > 1200) {
        replaceText({parentSelector: ".portfolio_preview__slide",
           textSelector: "",
           textBlock: ""
          });
      } 
    } catch (e) {
      // console.log(e);
    }

    try {
      if (window.innerWidth > 1200) {
        showTextOnBlur('.portfolio__desc_card', '.portfolio_card_desc');
      }
    } catch (e) {
      // console.log(e);
    }

    try {
      if (window.innerWidth > 1200) {

          openItemsMobile({
            parentSelector: ".portfolio__slider_container",
            itemsSelector: ".portfolio__desc_card",
            openBtnSelector: "[data-openMorePortfolio]",
            closeBtnSelector: "[data-closePortfolio]",
            count: 4 
          });
      } else {
        tabsOneBtn({
          tabsParentSelector: '.portfolio',
          articleCards: '.portfolio__desc_card',
          btnSelector: '[data-openMorePortfolio]',
        });
      }
    } catch (e) {
      // console.log(e);

    }

 // comments slider
      try {
        if (window.innerWidth > 1200) {
          sliderWithoutnumbers({slider: '.comments__list',
            prevArrow: `<button class="comments__arrow comments__arrow--prev   button--small_with_arrow" data-commentsPrevArrow></button>`,
            nextArrow: `<button class="comments__arrow comments__arrow--next  button--small_with_arrow" data-commentsNextArrow></button>`,
            arrowsPar: true,
            dotsPar: false,
            speedPar: 500,
            firstSlidePar: 0,
            variableWidthPar: false,
            centerModePar: false,
            slidesToShowPar: 2,
            slidesToScrollPar: 2
          });
        } else {
          document.querySelector('.comments__list').style.width = '100%';
          openItemsMobile({
            parentSelector: ".comments",
              itemsSelector: "[data-comments]",
             openBtnSelector: "[data-openMore]",
             closeBtnSelector: ".comments_link",
             count: 3 
            });
        }
      } catch (e) {
        // console.log(e);
      }

   // open comments
       try {
          readMoreText({
            commentSelector: '[data-comments]',
            textBox: '.comments__list__item',
            textSelector: '[data-comments-text]',
            openBtn: '[data-comment_open]',
            closingBtn: '[data-comment_close]',
            openClass: 'scroll',
            cutLenghtDesc: 1000,
            cutLenghtMobile: 500
          });

        checkRating('[data-aboutCommentBox]', '.star_icon', '.rating_current', 'star_icon--checked');
      } catch (e) {
        // console.log(e);
      }

  // check checkbox
      try {
        checkCheckbox('[data-checkbox_label]', '[data-checkbox]');
      } catch (e) {
        // console.log(e);
      }

 //calc
      try {
        calc();
      } catch (e) {
        // console.log(e);
      }

    //designer team
      try {
        if (window.innerWidth < 1200) {
          swipeToRight({
            slider: ".designer_team__cards_list",
            arrowsPar: false,
            dotsPar: false,
            speedPar: 500,
            firstSlidePar: 0,
            variableWidthPar: true,
            centerModePar: false,
            slidesToShowPar: 1,
            slidesToScrollPar: 1
          });
        }
      } catch (e) {
        // console.log(e);
      }

      try {
        if (window.innerWidth > 1200) {
          showTextOnBlur('.designer_team__card', '.designer_text__container');
          openSubmenu('.designer_team__card', '[data-with_sub]', '[data-sub]');
        }
      } catch (e) {
        // console.log(e);
      }
   

 //packages of services
    try {
      if (window.innerWidth < 678) {
        readMoreText({
          commentSelector: '.stages_of_work',
          textBox: '.stages_of_work',
          textSelector: '.services__text',
          openBtn: '.services__read_more_btn',
          closingBtn: '.services__hidden_btn',
          openClass: '.stages_of_work',
          cutLenghtDesc: 820,
          cutLenghtMobile: 820
        });
      }
      
    } catch (e) {
    // console.log(e);
    }

    

 try {
    if (window.innerWidth > 1200) {
      replaceText({parentSelector: '.tariff',textSelector: "[dataReplace]", textBlock: 'План расстановки'});
    }
  } catch (e) {
    // console.log(e);
  }

  // reviews
  try {
    if (window.innerWidth > 1200) {
        openItemsMobile({
          parentSelector: ".review__slider",
          itemsSelector: ".review__item",
          openBtnSelector: "[data-openMoreReview]",
          closeBtnSelector: "[data-closeReview]",
          count: 6
        });
    } else {
      tabsOneBtn({
        tabsParentSelector: '.reviews',
        articleCards: '.review__item',
        btnSelector: '[data-openMoreReview]',
      });
    }
  } catch (e) {
    // console.log(e);
  }

   // similar project
   try {

    if (window.innerWidth > 1200) {
      swipeToRight({
        slider: ".similar_projects__slider",
        arrowsPar: false,
        dotsPar: false,
        speedPar: 500,
        firstSlidePar: 0,
        variableWidthPar: true,
        centerModePar: false,
        slidesToShowPar: 4,
        slidesToScrollPar: 4
      });
    } else {
      swipeToRight({
        slider: ".similar_projects__slider",
        arrowsPar: false,
        dotsPar: false,
        speedPar: 500,
        firstSlidePar: 0,
        variableWidthPar: true,
        centerModePar: false,
        slidesToShowPar: 1,
        slidesToScrollPar: 1
      });
    }
     
    
  } catch (e) {
    // console.log(e);
  }

  // modals

  try {
    modal('[data-call-btn]', '[data-back_call_modal]', '.close_modal');
    } catch (e) {
      // console.log(e);
    }

    try {
      openThanksModal('[data-thanks_modal]', '[data-back_call_modal]');

      function sentFormId() {
        const modalTrigger = document.querySelectorAll('[data-call-btn]');

          modalTrigger.forEach(btn => {
            btn.addEventListener('click', () => {
                const formInput = document.querySelector("#aboutInput");
      
                formInput.value =  btn.getAttribute('data-ident');
            });
            
          });
         
      }

      sentFormId();
       
      } catch (e) {
        //  console.log(e);
       
      }

    try {
      function doInputMask() {
        const telInput = document.querySelectorAll("[data-telInput]");
        const yourTelInput = document.querySelectorAll(".tel input");
        let im = new Inputmask("+7 (999) 999-9999");
        
        telInput.forEach(item => {
          im.mask(item);
        });

        yourTelInput.forEach(point => {
          im.mask(point);
        });
      }

      doInputMask();
      
    } catch(e) {
      
    } 

    });