function showTextOnBlur(mainContainer, bgSelector) {
    const container = document.querySelectorAll(mainContainer);

    container.forEach(item => {
        const blurBgContainer = item.querySelector(bgSelector);

        item.addEventListener('mouseover', ()=> {
            blurBgContainer.classList.add('blurBg');
        });

        item.addEventListener('mouseout', ()=> {
            blurBgContainer.classList.remove('blurBg');
        });
    });
}

export default showTextOnBlur;