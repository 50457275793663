function openItemsMobile({parentSelector, itemsSelector, openBtnSelector, closeBtnSelector, count}) {
    const parent = document.querySelector(parentSelector),
          items = document.querySelectorAll(itemsSelector),
          openBtn = document.querySelector(openBtnSelector),
          closeBtn = document.querySelector(closeBtnSelector);

          function hide() {
            items.forEach(item => {
                item.classList.add('hide');
            })

            closeBtn.classList.add('hide');

            for(let i=0; i < count; i++) {
                items[i].classList.remove('hide');
              }
          }

          hide();

        openBtn.addEventListener('click', ()=> {
          if(count == 4 || count == 6) {
            parent.classList.add('scroll', 'scroll--margin_top');
          }

          if(count == 4) {
            parent.classList.add('portfolio_bigger_container');
          }

          if(count == 6) {
            parent.classList.add('review__slider--opened');
          }

            items.forEach(item => {
                item.classList.remove('hide');
            })
            closeBtn.classList.remove('hide');
            openBtn.classList.add('hide');
            
        });

        closeBtn.addEventListener('click', ()=> {
          if(count == 4 || count == 6) {
            parent.classList.remove('scroll');
          }

          if(count == 4) {
            parent.classList.remove('portfolio_bigger_container');
          }

          if(count == 6) {
            parent.classList.remove('review__slider--opened');
          }

            hide();
            openBtn.classList.remove('hide');
          
            
        });            
}

export default openItemsMobile; 



