import 'slick-carousel';
import $ from "jquery";

function sliderWithSetings({slide, slider, prevArrow, nextArrow, currentCounter, arrowsPar, dotsPar, appendDotsSelector, speedPar, firstSlidePar, variableWidthPar,centerModePar, slidesToShowPar, slidesToScrollPar}) {
    const slides = document.querySelectorAll(slide),
          current = document.querySelector(currentCounter),
          slickDots = document.querySelectorAll(".slick-dots li"),
          textSlider = document.querySelector(".slider_names"),
          textSlide = document.querySelectorAll(".slider_names__item"),
          textSlideWidth = window.getComputedStyle(textSlide[0]).width,
          sliderContainer = $(slider),
          prev = $(prevArrow),
          next = $(nextArrow);
          
    
    let slideIndex = 1,
        offset = 0; 

    textSlider.style.width = 100 * textSlide.length + '%';

    sliderContainer.slick({
        arrows: arrowsPar,
        dots: dotsPar,
        appendDots: $(appendDotsSelector),
        speed: speedPar,
        initialSlide: firstSlidePar,
        variableWidth: variableWidthPar,
        centerMode: centerModePar,
        slidesToShow: slidesToShowPar,
        slidesToScroll: slidesToScrollPar
      });
   
    if(slides.length < 10){
        current.textContent = `0${slideIndex}`;
    } else {
        current.textContent = slideIndex;
    }

    function currentSlide() {
        if(slides.length < 10){
            current.textContent = `0${slideIndex}`;
        } else {
            current.textContent = slideIndex;
        }
    }

    function undesablaedPrev() {
        if(slideIndex == 1) {
            prev.disabled = true;
        } else {
            prev.disabled = false;
        }
    }

    function slideIndexUp() {
        if(slideIndex == slides.length) {
            slideIndex = 1;
        } else {
            slideIndex++;
        }
    }

    function slideIndexdown() {
        if(slideIndex == 1) {
            slideIndex = slides.length;
        } else {
            slideIndex--;
        }
    }
    function deleteNotDegets(str) {
        return +str.replace(/\D/g, '');
    }

    function goTextToRight() {
        if(offset == deleteNotDegets(textSlideWidth) * (slides.length-1)){
            offset = 0;
        } else {
            offset += deleteNotDegets(textSlideWidth);
        }

        textSlider.style.transform = `translateX(-${offset}px)`;
    }

    function goTextToLeft() {
        if(offset == 0) {            
            offset = deleteNotDegets(textSlideWidth) * (slides.length-1);
        } else {
            offset -= deleteNotDegets(textSlideWidth);
        }
        textSlider.style.transform = `translateX(-${offset}px)`;
    }

    function gotoRight() {
        sliderContainer.slick('slickNext');
        slideIndexUp();
        currentSlide();
        undesablaedPrev();
        goTextToRight();
    }

    function gotoLeft() {
        sliderContainer.slick('slickPrev');
        slideIndexdown();
        currentSlide();
        undesablaedPrev();

        goTextToLeft();

        prev.classList.add('opacity_none');
    }

    prev.on("click", function(event) {
        event.preventDefault();
        gotoLeft();
    });
    
    next.on("click", function(event) {
        event.preventDefault();
        gotoRight();
    });

    sliderContainer.on("swipe", function(event) {
        event.preventDefault();

        gotoRight();
    });

    slickDots.forEach(dot => {
        dot.addEventListener('click', () => {
            slideIndexUp();
            currentSlide();
        })
     })
   
}

export default sliderWithSetings;
